import { getAllUsers, getAuthUserData } from "./chatUsersReducer";
import Axios from "axios";

const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS';
const TOKEN_USER_UUID_GOTTEN = 'TOKEN_USER_UUID_GOTTEN';
const ON_LINK_GOTTEN = 'ON_LINK_GOTTEN';
const GET_OUTPUT_PLAYER_VOICE_FROM_CLIENT = 'GET_OUTPUT_PLAYER_VOICE_FROM_CLIENT';
const GET_OUTPUT_PLAYER_SCREEN_FROM_SERVER = 'GET_OUTPUT_PLAYER_SCREEN_FROM_SERVER';
const SET_SOCKET_AND_PEER = 'SET_SOCKET_AND_PEER'
const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY'

let initialState = {
	initialized: false,
	linkIsFetched: false,
	linkForSS: '',
	type: 1,
	onDialog: false,
	outputPlayerVoiceFromClient: null,
	outputPlayerScreemFromSS: null,
	ip: '',
	socketIO: {},
	peerJS: {},
	activeCompany: ''
}

export const chatAppReducer = (state = initialState, action) => {
	switch (action.type) {
		case INITIALIZED_SUCCESS:
			return {
				...state,
				initialized: true
			};
		case GET_OUTPUT_PLAYER_VOICE_FROM_CLIENT:
			return {
				...state,
				outputPlayerVoiceFromClient: action.voice
			}
		case GET_OUTPUT_PLAYER_SCREEN_FROM_SERVER:
			return {
				...state,
				outputPlayerScreemFromSS: action.screen
			}
		case ON_LINK_GOTTEN:
			return {
				...state,
				linkIsFetched: true,
				linkForSS: action.link,

			}
		case SET_SOCKET_AND_PEER:
			return {
				...state,
				ip: action.payload.ip,
				socketIO: action.payload.socket_io,
				peerJS: action.payload.peer_js
			}
		case SET_ACTIVE_COMPANY:
			return {
				...state,
				activeCompany: action.id
			}
		default:
			return state
	}
}

//AC
export const setInitializedSuccess = () => ({ type: INITIALIZED_SUCCESS });
export const setLinkForSS = (link) => ({ type: ON_LINK_GOTTEN, link })
// export const toggleOnDialog = () => ({ type: ON_DIALOG })
// export const setOutputPlayerVoiceFromClient = (voice) => ({ type: GET_OUTPUT_PLAYER_VOICE_FROM_CLIENT, voice })
export const setOutputPlayerScreenFromSS = (screen) => ({ type: GET_OUTPUT_PLAYER_SCREEN_FROM_SERVER, screen })
export const setIpSocketAndPeer = (payload) => ({ type: SET_SOCKET_AND_PEER, payload })
export const setActiveCompany = (id) => ({ type: SET_ACTIVE_COMPANY, id })
//thunk
export const initializeApp = () => async (dispatch) => {
	let promise = dispatch(getAllUsers());

	Promise.all([promise]).then(() => {
		dispatch(setInitializedSuccess());
	})

}

export const getIpSocketAndChat = () => async (dispatch) => {
	try {
		const result = await Axios.get('https://ru1-1.getway.server.viexpo.ru/api/service/get-chat?event_uuid=12345-123-123&zone_uuid=asdsda-123sdasd-1231');

		dispatch(setIpSocketAndPeer(result.data));
	} catch (e) {
		console.log(e)
	}

}