import axios from "axios";
import { login, setError } from "../redux/auth-reducer";
import store from "../redux/redux-store";

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
export const ax = axios.create({
  // withCredentials: true,
  baseURL: 'https://ru1-1.getway.server.viexpo.ru',
  // baseURL: 'http://localhost:8000/',
  headers: {
    'Content-Type': 'application/json',
    'x-viexpo-tenant': '0',
    'x-viexpo-session-token': localStorage.getItem('token'),
    'Access-Control-Allow-Origin': '*'
  }
});

// Response interceptor CHECK AUTH
ax.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.data.resultCode === 401) {
    if (!(window.location.pathname.includes('auth') || window.location.pathname === "/")) {
      store.dispatch(login(false));
      store.dispatch(setError('auth'));
    }

  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export const getToken = () => {
  return localStorage.token;
}
