import { ax, getToken } from "./api-setup";

// Constants

export const GET_ALL_STANDS = 'api/event/stand/show-stands-for-event';
export const CREATE_STAND = 'api/event/stand/create';
export const GET_ALL_USERS = 'api/account/admin/get-all-system-users';
export const UPDATE_SPEAKERS = 'api/event/stand/update-stand-speakers';
export const UPDATE_STAND = '/api/event/stand/update-stand-info';
export const GET_FULL_STAND_INFO = '/api/event/stand/show-stand-data';
export const UPDATE_STAND_CONTENT = '/api/event/stand/update-stand-content';
export const DELETE_STAND = '/api/event/stand/delete';




// Methods

export const standsAPI = {
    getStands(event_uuid) {
        return ax.post(`${GET_ALL_STANDS}`, {
            event_uuid: event_uuid
        },
            {
                headers: {
                    'x-viexpo-session-token': localStorage.getItem('token'),
                },
            }).then(response => response.data)
    },
    createStand(formData) {
        return ax.post(`${CREATE_STAND}`, formData);
    },
    getAllUsers() {
        return ax.get(`${GET_ALL_USERS}`);
    },
    updateSpeakers(formData) {
        return ax.post(`${UPDATE_SPEAKERS}`, formData);
    },
    updateStand(formData) {
        return ax.post(`${UPDATE_STAND}`, formData);
    },
    getFullStandInfo(formData) {
        return ax.post(`${GET_FULL_STAND_INFO}`, formData)
    },
    deleteStand(formData) {
        return ax.post(`${DELETE_STAND}`, formData)
    },
    updateStandContent(formData, config) {
        return ax.post(`${UPDATE_STAND_CONTENT}`, formData, config).then(response => response.data)
    },
}

