import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth-reducer';
import { BlacklistReducer } from './blackList-reducer';
import eventsReducer from './events-reducer';
import reportsReducer from './reports-reducer';
import standsReducer from './standsReducer';
import { StatisticsReducer } from './statistics-reducer';
import { chatAppReducer } from './chatAppReducer';
import { chatUsersReducer } from './chatUsersReducer';
import usersReducer from './users-reducer';
import videosReducer from './videos-reducer';

let reducers = combineReducers({
    customers: usersReducer,
    auth: authReducer,
    events: eventsReducer,
    reports: reportsReducer,
    blackLists: BlacklistReducer,
    statistics: StatisticsReducer,
    videos: videosReducer,
    stands: standsReducer,
    chat: chatAppReducer,
    chatUsers: chatUsersReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
export default store;