import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIpSocketAndChat } from '../../redux/chatAppReducer';
import ChatContainer from './ChatContainer';


const ChatApp = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getIpSocketAndChat())
  }, [])

  return (
    <div style={{ marginBottom: '25px' }}>
      <ChatContainer />
    </div>
  )
}

export default ChatApp

