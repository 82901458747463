import React, { useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { UsersEditDialog } from "../Reports/users-edit-dialog/UsersEditDialog";
import 'swiper/swiper.min.css';
import "swiper/modules/pagination/pagination.scss"
import "swiper/modules/navigation/navigation.scss"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import SwiperCore, {
    Pagination, Navigation
} from 'swiper';
import { Divider } from "@material-ui/core";
import { setActiveCompany } from "../../redux/chatAppReducer";

SwiperCore.use([Pagination, Navigation]);

const slider = [
    { media: 'https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/slides/program_1.png' },
    { media: 'https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/slides/program_2.png' },
    { media: 'https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/slides/program_3.png' },
    { media: 'https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/slides/program_4.png' },
    { media: 'https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/slides/program_5.png' },
    { media: 'https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/slides/program_6.png' },
]

const EventCard = ({ id, image, active, name, date }) => {
    return (
        <div className="col-md-6" >
            <div className="d-flex flex-wrap align-items-top mb-5">
                {/* begin::Image */}
                <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                    <div
                        className="symbol-label"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                                image || "/media/bg/img-42.jpg"
                            )})`,
                        }}
                    ></div>
                </div>
                {/* end::Image */}

                {/* begin::Title */}
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3" style={{ maxWidth: "75%" }}>
                    <NavLink
                        to={`/dashboard/${id ? "online/" + id : "offline/1"}`}
                        className={`font-size-lg text-dark-75 text-hover-primary font-weight-bolder`}
                        style={active === false ?
                            {
                                pointerEvents: 'none',
                                opacity: 0.5
                            }
                            :
                            {}
                        }
                    >
                        {id ? name : "Оффлайн выставка"}
                    </NavLink>
                    {/* end::Title */}

                    {/*<span className="text-muted font-weight-bold font-size-sm my-1 mb-2">
                </span>*/}
                    <span className="text-muted font-weight-bold font-size-sm">
                        {date ? "Дата проведения: " + moment(date).format('DD-MM-YYYY') : "Дата проведения: постоянно"}
                    </span>
                </div>
            </div>
        </div>
    )
}

const TutorialCard = ({ id, image, active, name, date }) => {
    return (
        <div className="col-md-6" >
            <div className="d-flex flex-wrap align-items-top mb-5">
                {/* begin::Image */}
                <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                    <div
                        className="symbol-label"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                                image || "/media/bg/img-42.jpg"
                            )})`,
                        }}
                    ></div>
                </div>
                {/* end::Image */}

                {/* begin::Title */}
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3" style={{ maxWidth: "75%" }}>
                    <NavLink
                        to={`/dashboard/${id ? "online/" + id : "offline/tutorial"}`}
                        className={`font-size-lg text-dark-75 text-hover-primary font-weight-bolder`}
                        style={active === false ?
                            {
                                pointerEvents: 'none',
                                opacity: 0.5
                            }
                            :
                            {}
                        }
                    >
                        {id ? name : "Обучающая сцена"}
                    </NavLink>
                    {/* end::Title */}

                    {/*<span className="text-muted font-weight-bold font-size-sm my-1 mb-2">
                </span>*/}
                    <span className="text-muted font-weight-bold font-size-sm">
                        {date ? "Дата проведения: " + moment(date).format('DD-MM-YYYY') : "Дата проведения: постоянно"}
                    </span>
                </div>
            </div>
        </div>
    )
}


const EventBlock = ({ active, company }) => {
    const dispatch = useDispatch();
    const [ModalVisible, setModalVisible] = useState(false)

    const handleSetActiveCompany = id => {
        dispatch(setActiveCompany(id))
        localStorage.setItem('activeCompany', id)
    }

    return (
        <div className="col-md-12">
            <div className="d-flex align-items-top mb-5">
                {/* begin::Image */}
                <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-8">
                    <div
                        className="symbol-label"
                        style={{ backgroundImage: `url(${toAbsoluteUrl(`/media/bg/${company.eventImg}`)})`, width: 300, height: 200 }}
                    ></div>
                </div>
                {/* end::Image */}

                {/* begin::Title */}
                <div className="d-flex align-items-top mb-5 flex-column w-100">
                    {company.eventsDescription.map((ev, index) => {
                        return (
                            <div key={index} className={index + 1 !== company.eventsDescription.length ?
                                "d-flex flex-column flex-grow-1 my-lg-5 pr-3"
                                :
                                "d-flex flex-column flex-grow-1 my-lg-0 pr-3"
                            }
                            >
                                <div style={{ maxWidth: 500 }}>
                                    <NavLink
                                        to={`/dashboard/`}
                                        className={`font-size-lg text-dark-75 text-hover-primary font-weight-bolder`}
                                        style={active === false ?
                                            {
                                                pointerEvents: 'none',
                                                opacity: 0.5
                                            }
                                            :
                                            {}
                                        }
                                    >
                                        <span style={{ color: '#00b0bd', fontWeight: 600, fontSize: 20 }}>{ev.header}</span>
                                    </NavLink>
                                    <p style={{ fontSize: 14, paddingTop: 8 }}>{ev.title}</p>
                                    {/* end::Title */}

                                    {/*<span className="text-muted font-weight-bold font-size-sm my-1 mb-2">
                    </span>*/}
                                    <span className="text-muted font-weight-bold font-size-sm" style={{ fontSize: 16 }}>
                                        Дата проведения: <span style={{ color: '#3F4254' }}>{ev.eventDate}</span>
                                    </span>
                                </div>

                                <div style={{ paddingTop: 45, display: 'flex', justifyContent: 'flex-end' }}>

                                    {/* <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setModalVisible(true)}
                        >
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Shedule-01.svg")} />
                            </span> Программа
                        </button> */}

                                    {ev.eventRoutes.map((route, idx) =>
                                        <NavLink to={`/dashboard/${route}`}>
                                            <button type="button" className="btn btn-primary" style={{ marginRight: '1.5em', width: '100px' }} onClick={() => handleSetActiveCompany(ev.eventsID[idx])}>{ev.events[idx]}</button>
                                        </NavLink>
                                    )}
                                </div>

                                {ModalVisible && (
                                    <Modal
                                        size="lg"
                                        show={ModalVisible}
                                        onHide={() => setModalVisible(false)}
                                        aria-labelledby="example-modal-sizes-title-lg"
                                    >
                                        <div>
                                            <Swiper
                                                slidesPerView={1}
                                                //onSlideChange={() => console.log('slide change')}
                                                //onSwiper={(swiper) => console.log(swiper)}
                                                navigation={true}
                                                pagination={{
                                                    "type": "progressbar"
                                                }}
                                            >
                                                {slider && slider.map((item, index) =>
                                                    <SwiperSlide key={index}>
                                                        <img src={item.media} style={{ width: '100%', height: 'auto' }} alt="" />
                                                    </SwiperSlide>
                                                )}
                                            </Swiper>
                                        </div>
                                    </Modal>
                                )}

                                {/*<div style={{ maxWidth: 300, paddingTop: 45 }}>
                        <NavLink to={`/dashboard/${"online"}`}>
                            <button type="button" className="btn btn-primary">Посетить мероприятие</button>
                        </NavLink>
                    </div>*/}
                                {company.eventsDescription.length > 1 && index + 1 !== company.eventsDescription.length &&
                                    <Divider className="mt-10" variant="middle" />}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export function EventsList({ company }) {
    return (
        <>
            <div className={`card card-custom`} style={{ paddingTop: 20, marginBottom: '1.5em' }}>
                <div className="card-body pt-2">
                    <div className="row">
                        <EventBlock company={company} />
                    </div>
                </div>
            </div>
        </>
    );
}
