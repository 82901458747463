import { ax, getToken } from "./api-setup";

//Constants

export const GET_ALL_REPORTS = '/api/report/main/get-all-reports';
export const ADD_REPORT = '/api/report/main/create';
export const GET_REPORT = '/api/report/main/get-report-info';
export const UPDATE_REPORT = '/api/report/main/update-report';
export const UPDATE_REPORT_USERS = '/api/report/speakers/update-speakers';
export const UPDATE_REPORT_EVENTS = 'api/updatereportevents';
export const DELETE_REPORT = '/api/report/main/delete';
export const UPDATE_REPORT_FILES = 'api/updatereportcontent';
export const GET_FULL_REPORT_INFO = '/api/report/content/show-content-list'
export const UPDATE_REPORT_CONTENT = '/api/report/content/update-report-content';

//Methods

export const reportsAPI = {
    getAllReports() {
        return ax.get(
            GET_ALL_REPORTS,
            {
                headers: {
                    'x-viexpo-session-token': localStorage.getItem('token'),
                },
            },
        )
    },
    addReport(report) {
        return ax.post(
            ADD_REPORT,
            report,
            {
                headers: {
                    'x-viexpo-session-token': localStorage.getItem('token'),
                },
            },
        )
    },
    updateReport(report) {
        return ax.post(
            UPDATE_REPORT,
            report,
            {
                headers: {
                    'x-viexpo-session-token': localStorage.getItem('token'),
                },
            },
        )
    },
    deleteReport(report_uuid) {
        return ax.post(
            DELETE_REPORT,
            { report_uuid },
            {
                headers: {
                    'x-viexpo-session-token': localStorage.getItem('token'),
                },
            },
        )
    },
    getReport(report_uuid) {
        return ax.post(
            GET_REPORT,
            {
                report_uuid: report_uuid.trim(),
            },
            {
                headers: {
                    "x-viexpo-session-token": localStorage.getItem('token'),
                },
            },
        );
    },
    updateReportUsers(reportId, usersIds) {
        return ax.post(
            UPDATE_REPORT_USERS,
            {
                report_uuid: reportId,
                speakers: usersIds,
            },
            {
                headers: {
                    "x-viexpo-session-token": localStorage.getItem('token'),
                },
            },
        );
    },

    updateReportEvents(values) {
        return ax.put(`${UPDATE_REPORT_EVENTS}/${getToken()}`, {
            obj_id: values.id,
            reports_id: values.reports_ids
        })
    },
    updateFiles(formData, onUploadProgress) {
        return ax.post(`${UPDATE_REPORT_FILES}/${getToken()}`, formData, onUploadProgress)
            .then(response => response.data);
    },
    getFullReportInfo(formData) {
        return ax.post(`${GET_FULL_REPORT_INFO}`, formData)
    },
    updateReportContent(formData, config) {
        return ax.post(`${UPDATE_REPORT_CONTENT}`, formData,
            config).then(response => response.data)
    },
}
