import React from 'react'
import { Webgl } from '../../../app/pages/open';
import ChatApp from '../../Chat/ChatApp';


export const EventComponent = ({ webglPath }) => {
	return (
		<>
			<div style={{
				width: '110%',
				marginLeft: '-5%',
				backgroundColor: 'transparent',
				display: 'flex',
				flexDirection: 'row,',
				justifyContent: 'space-between',
			}}>
				<div style={{ width: '392px', marginRight: '15px' }}>
					<ChatApp />
				</div>
				<div style={{ width: '100%', height: '81vh' }}>
					<Webgl webglPath={webglPath} />
				</div>
			</div></>
	)
}