import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as auth from "../../../modules/Auth";
import SVG from "react-inlinesvg";
import CustomPasswordInput from "../../../../components/controls/usePasswordToggle/usePasswordToggle"
import { authAPI } from "../../../../api/api";
import Activation from "./Activation";
import { Select } from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";

const initialValues = {
  login: "",

  first_name: "",
  last_name: "",
  secon_name: "",

  sex: "n",
  agreement: false,

  company_name: "",
  company_position: "",
  Shtani: "0",
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);
  const [isError, setisError] = useState(false);
  const blackList = ['yandex.ru', 'gmail.com'];

  Yup.addMethod(Yup.string, `blacklist`, function (message) {
    return this.test(`test-name`, message, function (value) {
      let check = false;

      blackList.forEach(element => {
        if (value.endsWith(element)) {
          check = true;
        }
      });

      return !check;
    });
  });
  const RegistrationSchema = Yup.object().shape({
    login: Yup.string()
      .email("Некорректный формат почты")
      .matches(/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/, 'Неверный формат ввода')
      .min(3, "Минимум 3 символа")
      .max(50, "Максимум 50 символов")
      //.blacklist('Регистрация с этого домена запрещена')
      .required("Логин обязателен"),
    first_name: Yup.string()
      .min(2, "Введите не менее 2 симолов")
      .max(50, "Имя не может содержать более 50 символов")
      .matches(/^[а-яА-ЯёЁa-zA-Z][а-яА-ЯёЁa-zA-Z -]{1,20}$/, 'Первый символ обязательно буква, лимит 2-20 символов')
      .required("Имя обязательно"),
    last_name: Yup.string()
      .min(2, "Введите не менее 2 симолов")
      .max(50, "Фамилия не может содержать более 50 символов")
      .matches(/^[а-яА-ЯёЁa-zA-Z][а-яА-ЯёЁa-zA-Z -]{1,20}$/, 'Первый символ обязательно буква, лимит 2-20 символов')
      .required("Фамилия обязательна"),
    secon_name: Yup.string()
      .min(2, "Введите не менее 2 симолов")
      .max(50, "Отчество не может содержать более 50 символов")
      .matches(/^[а-яА-ЯёЁa-zA-Z][а-яА-ЯёЁa-zA-Z -]{1,20}$/, 'Первый символ обязательно буква, лимит 2-20 символов')
      .required("Отчество обязательна"),
    company_name: Yup.string()
      .required("Компания обязательна"),
    company_position: Yup.string()
      .required("Позиция обязательна"),
    password: Yup.string()
      .min(6, "Пароль должен содержать 6 и более символов, большие латинские буквы, маленькие латинские буквы и цифры.")
      .max(20, "Максимум 20 символов")
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/, 'Пароль должен содержать 6 и более символов, большие латинские буквы, маленькие латинские буквы и цифры.')
      .required("Пароль обязателен"),
    changepassword: Yup.string()
      .required("Пожалуйста введите подтверждение пароля")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Пароль и подтвержденный пароль не совпадают"
        ),
      }),
    agreement: Yup.bool()
      .oneOf([true], 'Вы должны принять политику конфиденциальности'),
    sex: Yup.string()
      .oneOf(['m', 'f'], 'пол обязателен')
      .required('пол обязателен')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname, touched, errors) => {
    if (touched[fieldname] && errors[fieldname]) {
      return "is-invalid";
    }

    if (touched[fieldname] && !errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: RegistrationSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     setSubmitting(true);
  //     enableLoading();
  //     authAPI.registration(values).then(response => {
  //       disableLoading();
  //       setSubmitting(false);
  //       console.log('response', response);
  //       switch (response.status) {
  //         case 200:
  //         case 204: {
  //           disableLoading();
  //           setSubmitting(false);
  //           setHide(false);
  //           setisError(true);
  //           props.history.push('/auth/login');
  //           break;
  //         }
  //         case 403: {
  //           setStatus(
  //               intl.formatMessage({
  //                 id: "AUTH.VALIDATION.INVALID_LOGIN_DOMAIN",
  //               })
  //           );
  //           break;
  //         }
  //         case 500: {
  //           setStatus(
  //               intl.formatMessage({
  //                 id: "AUTH.VALIDATION.INVALID_LOGIN_NAME",
  //               })
  //           );
  //           break;
  //         }
  //         default: {
  //           setStatus('Ошибка сервера. Пожалуйста обратитесь к админастратору.');
  //         }
  //       }
  //     })
  //       .catch((error) => {
  //         console.log('error', error.message)
  //         console.log('error', error.status)
  //         setSubmitting(false);
  //         setStatus(
  //           intl.formatMessage({
  //             id: "AUTH.VALIDATION.INVALID_LOGIN",
  //           })
  //         );
  //         disableLoading();
  //       });
  //   },
  //   validateOnChange: (o, t) => {
  //     console.log('o', o)
  //     console.log('t', t)
  //   }
  // });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegistrationSchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setSubmitting(true);
        enableLoading();
        authAPI.registration(values).then(response => {
          disableLoading();
          setSubmitting(false);
          console.log('response', response);
          switch (response.status) {
            case 200:
            case 204: {
              disableLoading();
              setSubmitting(false);
              setHide(false);
              setisError(true);
              props.history.push('/auth/login');
              break;
            }
            case 403: {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN_DOMAIN",
                })
              );
              break;
            }
            case 500: {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN_NAME",
                })
              );
              break;
            }
            default: {
              setStatus('Ошибка сервера. Пожалуйста обратитесь к админастратору.');
            }
          }
        })
          .catch((error) => {
            console.log('error', error.message)
            console.log('error', error.status)
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
            disableLoading();
          });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        touched,
        errors,
        getFieldProps,
        isValid,
        status,
      }) => (
        <div className="login-form login-signin" style={{ display: "block" }}>
          {/* begin::Alert */}
          {isError && (
            <>
              <div
                className="alert alert-custom alert-light-primary fade show mb-10"
                role="alert"
              >
                <div className="alert-icon">
                  <span className="svg-icon svg-icon-3x svg-icon-white">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}></SVG>{" "}
                  </span>
                </div>
                <div className="alert-text font-weight-bold">
                  Регистрация прошла успешно. Вам отправленно письмо с кодом подтверждения.
                </div>
              </div>
              <Activation />
            </>
          )}
          {/* end::Alert */}

          {hide ? (
            <>
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                  <FormattedMessage id="AUTH.REGISTER.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                  Введите свои данные для создания аккаунта
                </p>
              </div>

              <Form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={handleSubmit}
              >
                {/* begin: Alert */}
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: login */}
                <div className="form-group fv-plugins-icon-container mb-3">
                  <input
                    placeholder="Логин/Почта"
                    type="text"
                    className={`form-control form-control-solid ${getInputClasses("login", touched, errors)}`}
                    name="login"
                    {...getFieldProps("login")}
                  />
                  {touched.login && errors.login ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.login}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: login */}

                {/* begin: last_name */}
                <div className="form-group fv-plugins-icon-container mb-3">
                  <input
                    placeholder="Фамилия"
                    type="text"
                    className={`form-control form-control-solid ${getInputClasses("last_name", touched, errors)}`}
                    name="last_name"
                    {...getFieldProps("last_name")}
                  />
                  {touched.last_name && errors.last_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.last_name}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: last_name */}

                {/* begin: first_name */}
                <div className="form-group fv-plugins-icon-container mb-3">
                  <input
                    placeholder="Имя"
                    type="text"
                    className={`form-control form-control-solid ${getInputClasses("first_name", touched, errors)}`}
                    name="first_name"
                    {...getFieldProps("first_name")}
                  />
                  {touched.first_name && errors.first_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.first_name}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: first_name */}

                {/* begin: secon_name */}
                <div className="form-group fv-plugins-icon-container mb-3">
                  <input
                    placeholder="Отчество"
                    type="text"
                    className={`form-control form-control-solid ${getInputClasses("secon_name", touched, errors)}`}
                    name="secon_name"
                    {...getFieldProps("secon_name")}
                  />
                  {touched.secon_name && errors.secon_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.secon_name}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: secon_name */}

                {/* begin: company_name */}
                <div className="form-group fv-plugins-icon-container mb-3">
                  <input
                    placeholder="Компания"
                    type="text"
                    className={`form-control form-control-solid ${getInputClasses("company_name", touched, errors)}`}
                    name="company_name"
                    {...getFieldProps("company_name")}
                  />
                  {touched.company_name && errors.company_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.company_name}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: company_name */}

                {/* begin: company_position */}
                <div className="form-group fv-plugins-icon-container mb-3">
                  <input
                    placeholder="Должность"
                    type="text"
                    className={`form-control form-control-solid ${getInputClasses("company_position", touched, errors)}`}
                    name="company_position"
                    {...getFieldProps("company_position")}
                  />
                  {touched.company_position && errors.company_position ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.company_position}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: company_position */}

                {/* begin: Shtani */}
                <div role="group" aria-labelledby="my-radio-group">
                  <Select
                    name="Shtani"
                    label="Тип компании"
                    {...getFieldProps("Shtani")}
                  >
                    <option value="0">Интегратор/Реселлер/Провайдер</option>
                    <option value="1">Государственная организация или частная компания</option>
                    <option value="2">Производитель ПО и оборудования</option>
                    <option value="3">Другое</option>
                    <option value="4">Сотрудники Viexpo-а и Админы</option>
                  </Select>
                </div>
                {/* end: Shtani */}

                <div role="group" aria-labelledby="my-radio-group" style={{ paddingTop: 10, marginBottom: 15 }}>
                  <Select
                    name="sex"
                    label="пол"
                    {...getFieldProps("sex")}
                  >
                    <option value="n">Не выбрано</option>
                    <option value="m">Мужской</option>
                    <option value="f">Женский</option>
                  </Select>
                  {touched.sex && errors.sex ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.sex}</div>
                    </div>
                  ) : null}
                </div>

                {/* begin: Password */}
                <div className="input-group fv-plugins-icon-container mb-3">
                  <CustomPasswordInput
                    placeholder="Пароль"
                    InputClass="password"
                    getInputClasses={`form-control form-control-solid ${getInputClasses("password", touched, errors)}`}
                    formikProps={getFieldProps("password")}
                    touched={touched.password}
                    errors={errors.password}
                  />
                </div>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="input-group fv-plugins-icon-container mb-3">
                  <CustomPasswordInput
                    placeholder="Подтвердите пароль"
                    InputClass="changepassword"
                    getInputClasses={`form-control form-control-solid ${getInputClasses("changepassword", touched, errors)}`}
                    formikProps={getFieldProps("changepassword")}
                    touched={touched.changepassword}
                    errors={errors.changepassword}
                  />
                </div>
                {/* end: Confirm Password */}

                {/* begin: Terms and Conditions */}
                <div className="form-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="agreement"
                      className="m-1"
                      {...getFieldProps("agreement")}
                    />
                    <Link
                      to="registration"
                      onClick={() => setShow(true)}
                      className="btn link p-0 mr-1"
                      style={{ color: "#3F9BA9" }}
                    >
                      Я согласен с политикой конфиденциальности
                    </Link>
                    <span />
                  </label>
                  {touched.agreement && errors.agreement ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.agreement}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Terms and Conditions */}
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    <span>Отправить</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </button>

                  <Link to="/auth/login">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Отменить
                    </button>
                  </Link>
                </div>
              </Form>
            </>
          ) : null}

          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">Политика конфиденциальности</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <>
                <h1>Политика конфиденциальности персональных данных</h1>
                <p>Настоящая Политика конфиденциальности персональных данных (далее — Политика) действует в отношении всех персональных данных, которые АО «Аксофт» и его аффилированные лица могут получить от пользователя во время использования сайта <a
                  href="https://viexpo.ru/">https://viexpo.ru/</a>  (далее — Сайт).
                  АО «Аксофт» не контролирует и не несет ответственность за сайты третьих лиц, на которые пользователь может перейти по ссылкам, доступным на сайте Viexpo. На сайтах третьих лиц может быть собственная политика конфиденциальности и у пользователя могут собираться или запрашиваться иные персональные данные.
                </p>
                <p>Используя Услуги и предоставляя АО «Аксофт» информацию, необходимую для инициирования дальнейшего взаимодействия, Вы выражаете согласие на ее использование в соответствии с настоящей Политикой.</p>
                <p>Для конкретных Услуг, АО «Аксофт» может публиковать дополнительные положения, дополняющие настоящую Политику.</p>
                <div>
                  <h4>1. Персональные данные пользователей, которые получает и обрабатывает Viexpo.</h4>
                  <p>1.1. В рамках настоящей Политики под «персональными данными пользователя» понимаются:</p>
                  <p>1.1.1. Персональные данные, которые пользователь предоставляет о себе самостоятельно при регистрации (создании учётной записи) на Сайте или в процессе использования Услуг. Обязательная для предоставления (оказания) Услуг информация явно обозначена. К такой информации отнесены: фамилия, имя и отчество, адрес электронной почты, почтовый адрес доставки заказов, контактный телефон.</p>
                  <p>1.1.2. Иная информация о пользователе, сбор и/или предоставление которой определено Viexpo для предоставления отдельных Услуг дополнительно, о чём явно указано при заказе отдельных Услуг.</p>
                  <p>1.2. Персональные данные пользователя, предоставленные Viexpo, считаются не достоверными и могут быть блокированы до момента получения от пользователя или его законного представителя согласия на обработку персональных данных пользователя в любой дополнительно обозначенной Viexpo форме, помимо предусмотренной на Сайте.</p>
                </div>
                <div>
                  <h4>2. Цели обработки персональной информации пользователей</h4>
                  <p>2.1. АО «Аксофт» обрабатывает только те персональные данные, которые необходимы для оказания и улучшения качества Услуг.</p>
                  <p>2.2. Персональную информацию пользователя Viexpo может использовать в следующих целях:</p>
                  <p>2.2.1. Идентификация стороны в рамках оказания Услуги.</p>
                  <p>2.2.2. Предоставление пользователю персонализированных Услуг.</p>
                  <p>2.2.3. Улучшение качества Услуг и разработка новых.</p>
                  <p>2.2.4. Проведение статистических и иных исследований на основе обезличенных данных.</p>
                  <p>2.2.5. Предоставление персональных данных пользователя правообладателям, дистрибьюторам или реселлерам программного обеспечения в целях регистрации программного обеспечения на имя пользователя или организации, интересы которой представляет пользователь.</p>
                  <p>2.2.6. Осуществление и/или исполнение функций, полномочий и обязанностей, возложенных законодательством Российской Федерации на АО «Аксофт».</p>
                </div>

                <div>
                  <h4>3. Передача персональных данных пользователя третьим лицам</h4>
                  <p>3.1. В отношении персональных данных пользователя сохраняется конфиденциальность, кроме случаев обработки персональных данных, доступ неограниченного круга лиц к которым предоставлен пользователем либо по его просьбе.</p>
                  <p>3.2. АО «Аксофт» вправе передать персональную информацию пользователя третьим лицам в следующих случаях:</p>
                  <p>3.2.1. Пользователь предоставил свое согласие на такие действия, в том числе в целях регистрации программного обеспечения на имя пользователя правообладателями, дистрибьюторами или реселлерами программного обеспечения.</p>
                  <p>3.2.2. Передача необходима для достижения целей, осуществления и выполнения функций, полномочий и обязанностей, возложенных законодательством Российской Федерации на АО «Аксофт».</p>
                </div>

                <div>
                  <h4>3. Передача персональных данных пользователя третьим лицам</h4>
                  <p>3.1. В отношении персональных данных пользователя сохраняется конфиденциальность, кроме случаев обработки персональных данных, доступ неограниченного круга лиц к которым предоставлен пользователем либо по его просьбе.</p>
                  <p>3.2. АО «Аксофт» вправе передать персональную информацию пользователя третьим лицам в следующих случаях:</p>
                  <p>3.2.1. Пользователь предоставил свое согласие на такие действия, в том числе в целях регистрации программного обеспечения на имя пользователя правообладателями, дистрибьюторами или реселлерами программного обеспечения.</p>
                  <p>3.2.2. Передача необходима для достижения целей, осуществления и выполнения функций, полномочий и обязанностей, возложенных законодательством Российской Федерации на АО «Аксофт».</p>
                </div>

                <div>
                  <h4>4. Меры, применяемые для защиты персональных данных пользователей</h4>
                  <p>АО «Аксофт» принимает необходимые и достаточные организационные и технические меры для защиты персональных данных пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с персональными данными третьих лиц.</p>
                  <p>Viexpo предпринимает разумные меры для поддержания точности и актуальности имеющихся у АО «Аксофт» персональных данных, а также удаления устаревших и других недостоверных или излишних персональных данных.</p>
                </div>

                <div>
                  <h4>5. Права и обязанности пользователя</h4>
                  <p>5.1. Пользователь несёт ответственность за предоставление достоверных сведений, а также за обновление предоставленных данных в случае каких-либо изменений.</p>
                  <p>5.2. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть, а также параметры её конфиденциальности путем обращения в АО «Аксофт».</p>
                  <p>5.2.1. Пользователь вправе в любой момент отозвать согласие на обработку Viexpo персональных данных путём направления письменного уведомления на адрес: <a href="mailto:info@Viexpo.com">info@Viexpo.com</a>, в АО «Аксофт» с пометкой «отзыв согласия на обработку персональных данных», при этом что отзыв пользователем согласия на обработку персональных данных влечёт за собой удаление учётной записи пользователя с Сайта, а также уничтожение записей, содержащих персональные данные, в системах обработки персональных данных АО «Аксофт», что может сделать невозможным частичное пользование Сайтом.</p>
                  <p>5.3. Пользователь имеет право на получение информации, касающейся обработки его персональных данных в АО «Аксофт» для чего вправе направить письменный запрос на адрес: <a href="mailto:info@Viexpo.com">info@Viexpo.com</a> с пометкой «запрос информации о порядке обработки персональных данных».</p>
                  <p>5.4. Для исполнения положений в п. 5.2 и 5.3 настоящей Политики АО «Аксофт» может потребовать подтвердить личность пользователя, затребовав предоставления такого подтверждения в любой непротиворечащей закону форме.</p>
                </div>

                <div>
                  <h4>6. Обратная связь. Вопросы и предложения</h4>
                  <p>Все предложения или вопросы по поводу настоящей Политики следует сообщать АО «Аксофт» по телефону 8 800 200 5215 (звонок по России бесплатный) или по адресу электронной почты <a href="mailto:info@Viexpo.com">info@Viexpo.com</a>.</p>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => setShow(false)}
                className="btn btn-light btn-elevate"
              >
                Назад
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Formik>
  )
}

export default injectIntl(connect(null, auth.actions)(Registration));
