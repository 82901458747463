import React, { useEffect, useMemo, useState } from 'react';
import './ChatContainer.css';
import { useDispatch, useSelector } from 'react-redux'
import { WSSSContext } from './utils/Context';
import { Chat } from './components/common/chat/Сhat';
import { getAllStands } from '../../redux/standsReducer';
import { checkMicroAudioAccess } from './utils/CheckMicroVideoAccess';
import { getWayConnection } from './components/socket/getWayConnect';

function ChatContainer() {

  const dispatch = useDispatch();

  // useEffect(() => {
  //   checkMicroAudioAccess();
  // }, [])

  useEffect(() => {
    dispatch(getAllStands());
  }, [])

  const [contextWSSS, setContextWSSS] = useState({
    linkForSS: '',
    sessionToken: '',
    userUUID: '',
    type: '',
    lobbyUUID: '',
    userName: '',
    userLastName: '',
    stands: null,
    userPhone: null,
    userEmail: null,
    userCompany: null,
    userPosition: null,
    userRole: null,
    userSecondName: null,
  })

  const [lobbyUUID, setLobbyUUID] = useState('');

  //get initial data for GetWay and SoundServer
  const linkForSS = useSelector(state => state.chat.linkForSS);
  const sessionToken = useSelector(state => state.auth.user.session_uuid);
  const userUUID = useSelector(state => state.auth.user.user_uuid);
  const getConnectionType = useSelector(state => state.chat.type);
  const userName = useSelector(state => state.auth.user.first_name);
  const userSecondName = useSelector(state => state.auth.user.secon_name);
  const userLastName = useSelector(state => state.auth.user.last_name);
  const userPhone = useSelector(state => state.auth.user.phone);
  const userEmail = useSelector(state => state.auth.user.email);
  const userCompany = useSelector(state => state.auth.user.company_name);
  const userPosition = useSelector(state => state.auth.user.company_position);
  const userRole = useSelector(state => state.auth.user.system_role);
  const stands = useSelector(state => state.stands.stands);
  const ip = useSelector(state => state.chat.ip)
  const socketIO = useSelector(state => state.chat.socketIO)
  const peerJS = useSelector(state => state.chat.peerJS)
  const activeCompany = useSelector(state => state.chat.activeCompany) || localStorage.getItem('activeCompany')
  //get users from store
  // const getUsersFromStore = useSelector(state => state.chatUsers.usersUsers)

  useMemo(() => {
    setContextWSSS({
      linkForSS,
      sessionToken,
      userUUID,
      getConnectionType,
      lobbyUUID,
      userName,
      userLastName,
      stands,
      userPhone,
      userEmail,
      userCompany,
      userPosition,
      userRole,
      userSecondName,
      ip,
      socketIO,
      peerJS,
      activeCompany
    })
  }, [linkForSS,
    sessionToken,
    userUUID,
    getConnectionType,
    lobbyUUID,
    userName,
    userLastName,
    stands,
    userPhone,
    userEmail,
    userCompany,
    userPosition,
    userRole,
    userSecondName,
    ip,
    socketIO,
    peerJS,
    activeCompany])


  return (
    <WSSSContext.Provider value={contextWSSS}>
      <div className="home" style={{ textAlign: "center" }}>
        <Chat />
      </div>
    </WSSSContext.Provider >
  );
}

export default ChatContainer;