import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { WebglBuild } from "../../app/pages/close";
import { EventsWidget } from "./EventsWidget";
import { Webgl } from "../../app/pages/open";
import { Tutorial } from "../../app/pages/tutorial";
import ChatApp from '../Chat/ChatApp'
import { EventComponent } from "./DashBordRoute/DashboardRoute";



export function DashboardPage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Route exact path="/dashboard">
                    <EventsWidget />
                </Route>
                {/*<Route path="/dashboard/build">
                            <Webgl />
                        </Route>
                        <Route path="/dashboard/webglbuild">
                            <WebglBuild />
                        </Route>*/}
                {/* <Route path="/dashboard/online/:eventId">
                            <WebglBuild />
                        </Route> */}
                {/* <Route path="/dashboard/offline/1">
                            <Webgl />
                        </Route> */}
                {/* <Route path="/dashboard/offline/tutorial">
                            <Tutorial />
                        </Route> */}

                {/* нужен рефакторинг */}

                <Route path="/dashboard/online-1">
                    <EventComponent webglPath={"https://s-dt2.cloud.gcore.lu/viexpo-webgl/viexpo_client/event1/index.html"} />
                </Route>
                <Route path="/dashboard/online-2">
                    <EventComponent webglPath={"https://s-dt2.cloud.gcore.lu/viexpo-webgl/viexpo_client/event2/index.html"} />
                </Route>
                <Route path="/dashboard/technopolis-school">
                    <EventComponent webglPath={"https://s-dt2.cloud.gcore.lu/viexpo-webgl/Tehnopolis/school/index.html"} />
                </Route>
                <Route path="/dashboard/technopolis-expo">
                    <EventComponent webglPath={"https://s-dt2.cloud.gcore.lu/viexpo-webgl/Tehnopolis/expo/index.html"} />
                </Route>
                <Route path="/dashboard/mtpp">
                    <EventComponent webglPath={"https://s-dt2.cloud.gcore.lu/viexpo-webgl/mtpp/mtpp_build/index.html"} />
                </Route>
                <Route path="/dashboard/h2b">
                    <EventComponent webglPath={"https://s-dt2.cloud.gcore.lu/viexpo-webgl/h2b/h2b_build/index.html"} />
                </Route>
            </Switch>
        </Suspense>
    );
}
