/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { me } from "../../redux/auth-reducer";
import { Card, CardBody } from "../../_metronic/_partials/controls";

const alignCenter = {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    //   position: 'relative',
    //   top: '0',
    //   bottom: '0',
    //   left: '0',
    //   width: '100%',
    //   height: '100%',
    //   border: '0',
    paddingTop: '64%',
};



export function WebglBuild() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(me());
    }, [dispatch])

    const webglRef = useRef();

    let setToken = () => {
        webglRef.current.contentWindow.postMessage(JSON.stringify({

            key: 'token',
            data: localStorage.token

        }), "*");
    }

    return (
        <Card>
            <CardBody>
                <div className="embed-responsive" style={alignCenter}>
                    <iframe ref={webglRef} allow="microphone" className="embed-responsive-item" src="https://cdn.viexpo.ru/viexpo-webgl/webgl_aq_client/index.html"
                        title="Viexpo Build"
                        allowFullScreen
                        onLoad={setToken}></iframe>
                </div>
            </CardBody>
        </Card>
    );
}
