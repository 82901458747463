import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ChatControlButtons from '../../../chat-control-btns/ChatControlButtons'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { MenuItem } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { VoiceChatButtonsContext } from '../../../../../../utils/Context';
import { axoftCompany } from '../../../../../socket/constants';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { zones } from '../../../../../../utils/zonesData/zonesData';
import handRisenIcon from '../../../../../../assets/src/media/icons/handRisenExclamation.png'
import { conferenceHalls } from '../../../../../../utils/zonesData/ConferenceHalls';

const drawerWidth = 360;

const useStyles = makeStyles({
	toolbarRoot: {
		display: 'flex',
		width: '99.4%',
		backgroundColor: '#fff',
		position: 'fixed !important',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		padding: '0 !important',
		borderTop: ' 1px solid rgba(0, 0, 0, 0.12)',
		boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.1)',
		minHeight: '75px !important',
		marginLeft: '0.2%'
	},
	standTitle: {
		width: '100%',
		marginLeft: '1em',
		fontWeight: 700,
		fontSize: 18,
		textAlign: 'left'
	},
	standName: {
		width: '100%',
		marginLeft: '1em',
		fontWeight: 400,
		fontSize: 14,
		textAlign: 'left'
	},

	speaker: {
		backgroundColor: '#fff',
		color: '#262626',
		border: 'none',
		padding: '2px 6px',
		'&:hover': {
			backgroundColor: '#F1F9FA !important',
			border: 'none',
			boxShadow: 'none',
		},
		'&:active': {
			boxShadow: 'none',
			backgroundColor: '#F1F9FA !important',
			border: 'none',
		},
		'&:focus': {
			boxShadow: 'none',
			backgroundColor: '#F1F9FA !important',
			border: 'none',
		},
	},
});

//роли спикеров
export const VoiceTabPanel = () => {
	const { connectedUsers, socket, roomConnected, setConnectedUsers, currentRoom, setCurrentRoom, handRisen, mutedAutomatically, connectedUsersLength, activeCompany, isConnected } = useContext(VoiceChatButtonsContext);
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [isReadyToLoadVoice, setIsReadyToLoadVoice] = useState(false);
	const [recieverPeerUUID, setRecieverPeerUUID] = useState();
	const [recieverData, setRecieverData] = useState();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleClick = (event, peerUUID, data) => {
		setAnchorEl(event.currentTarget);
		setRecieverPeerUUID(peerUUID)
		setRecieverData(data)
	};

	const privatlyCallPeer = () => {
		handleClose();
		// setCurrentRoom(`${recieverData}_${recieverPeerUUID}`)
		setCurrentRoom(roomConnected.split('_')[1])
		setTimeout(() => {
			socket.emit('callPeer', anchorEl.id)
		}, 1000);
	}

	const backToMainLobby = () => {
		socket.emit('joinCompany', currentRoom)
		setCurrentRoom('');
	}

	const backToPreviousRoom = () => {
		socket.emit('joinEvent', currentRoom)
	}

	const handleMuteUser = peerUUID => {
		if (peerUUID !== connectedUsers[0].peerUUID) {
			let videoForMute = document.getElementById(`vid-${peerUUID}`);
			let audioForMute = document.getElementById(`audio-${peerUUID}`);
			if (videoForMute) {
				if (!videoForMute.muted || !audioForMute.muted) {
					audioForMute.pause();
					audioForMute.muted = true;
					videoForMute.pause();
					videoForMute.muted = true;
				} else {
					audioForMute.play();
					audioForMute.muted = false;
					videoForMute.play();
					videoForMute.muted = false;
				}
			}
			setConnectedUsers(connectedUsers => connectedUsers.map(user =>
				user.peerUUID === peerUUID ? { ...user, isMuted: !user.isMuted } : user))
		}
	}

	const handleMuteUserAsAdmin = (peerUUID, index) => {

		if (conferenceHalls.includes(roomConnected.split('_')[1]) && peerUUID !== connectedUsers[0].peerUUID && connectedUsers[0].userRole !== 3) {
			socket.emit('sendTo', {
				packet: {
					unmute: {
						state: !connectedUsers[index].isMuted
					}
				},
				peerUUID: peerUUID
			})

			setConnectedUsers(connectedUsers => connectedUsers.map(user =>
				user.peerUUID === peerUUID ? { ...user, isMuted: !user.isMuted } : user))
		}
	}

	useEffect(() => {
		if (roomConnected && conferenceHalls.includes(roomConnected.split('_')[1]) && mutedAutomatically) {
			setConnectedUsers(connectedUsers => connectedUsers.map(user =>
				user.userRole === 3 ? { ...user, isMuted: true } : user))
		}
	}, [roomConnected, connectedUsersLength, mutedAutomatically])

	useEffect(() => {
		setIsReadyToLoadVoice(isConnected)
	}, [isConnected])

	return (
		<>
			<Drawer
				sx={{
					position: 'relative',
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth - 2,
						height: '65vh',
						boxSizing: 'border-box',
					},
					'& .MuiDrawer-paper::-webkit-scrollbar': {
						width: '0px',
						background: 'transparent',
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<Toolbar classes={{ root: classes.toolbarRoot }} sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					}
				}} >
					{roomConnected && roomConnected.split('_')[0] === zones[roomConnected.split('_')[1]] ?
						<Typography noWrap component="div" className={classes.standTitle}>
							{zones[roomConnected.split('_')[1]]}
						</Typography>
						:
						!isReadyToLoadVoice ?
							<Typography noWrap component="div" className={classes.standTitle}>
								Загрузка...
							</Typography>
							:
							<Typography noWrap component="div" className={classes.standTitle}>
								Зона приватного общения
								{roomConnected !== activeCompany ?
									<ListItemButton
										className={classes.speaker}
										onClick={backToMainLobby}>
										<ListItemText
											primary={'Вернуться в общий зал'}
										// secondary={index % 2 === 0 ? 'Secondary text' : null}
										/>
									</ListItemButton>
									:
									<ListItemButton
										className={classes.speaker}
										onClick={backToPreviousRoom}>
										<ListItemText
											primary={`Вернуться в ${zones[currentRoom]} `}
										// secondary={index % 2 === 0 ? 'Secondary text' : null}
										/>
									</ListItemButton>
								}
							</Typography>
					}
				</Toolbar>
				<Divider />
				{connectedUsers &&
					<List sx={{ marginTop: '75px' }}>
						{connectedUsers.map((user, index) => (
							<ListItem
								className={classes.speaker}
								key={index}
								disablePadding
								secondaryAction={
									<>
										{handRisen[user.userUUID] && <img src={handRisenIcon} alt="" style={{ height: '16px' }} />}
										<IconButton edge="end" onClick={
											conferenceHalls.includes(roomConnected.split('_')[1]) ?
												() => handleMuteUserAsAdmin(user.peerUUID, index)
												:
												() => handleMuteUser(user.peerUUID)} >
											{(!user.state || user.state.isTalking === false) && !user.isMuted && !conferenceHalls.includes(roomConnected.split('_')[1]) ?
												< VolumeUpIcon />
												:
												!user.isMuted && user.state.isTalking === true ? <VolumeUpIcon style={{ color: '#00AFBC' }} />
													:
													<VolumeOffIcon style={{ color: '#FF94A1' }} />
											}
										</IconButton></>
								}>
								<ListItemButton
									id={user.peerUUID}
									className={classes.speaker}
									onClick={index !== 0 ? (e) => handleClick(e, user.peerUUID, `${user.userLastName} ${user.userName}`) : null}>
									<ListItemText
										aria-describedby={id}
										primary={`${user.userLastName} ${user.userName}`}
										secondary={user.userRole === 0 ? 'Суперадмин' : user.userRole === 1 ? 'Администратор' : user.userRole === 2 ? 'Спикер' : null}
									/>
								</ListItemButton>
								<Popover
									id={id}
									open={open}
									anchorEl={anchorEl}
									onClose={handleClose}
									PaperProps={{
										style: {
											border: '1px solid #d5f2f5',
											boxShadow: 'none',
											borderRadius: '8px',
											backgroundColor: '#fff',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'flex-start',
											alignItems: 'flex-start',
										}
									}}
									anchorPosition={{ top: 120, left: 700 }}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									transformOrigin={{
										vertical: 'center',
										horizontal: 'right',
									}}
								>
									<MenuItem
										type='button'
										sx={{
											padding: '6px 16px !important',
											textAlign: 'left',
											width: '100%'
										}}
										onClick={privatlyCallPeer}
									>Позвонить</MenuItem>
									{/* <MenuItem sx={{
										padding: '6px 16px !important',
										textAlign: 'left',
										width: '100%'
									}}>И сделать что-то ещё</MenuItem>
									<MenuItem sx={{
										padding: '6px 16px !important',
										textAlign: 'left',
										width: '100%'
									}}>И ещё</MenuItem> */}

								</Popover>
							</ListItem>
						))}
					</List>
				}
			</Drawer>
			<Box id='thisisid' sx={{
				width: drawerWidth,
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}>
				<ChatControlButtons />
			</Box>
		</>
	)
}