import { toInteger } from "lodash";
import { reportsAPI } from "../api/api";
import { getStringFromBool } from "../utils";

const SET_REPORTS = 'SET_REPORTS';
const SET_EDIT_REPORT = 'SET_EDIT_REPORT';
const CLEAR_EDIT_REPORT = 'CLEAR_EDIT_REPORT';
const SET_LOADING = 'SET_LOADING';
const SET_ACTIONS_LOADING = 'SET_ACTIONS_LOADING';

const AK_SET_REPORTS = 'AK_SET_REPORTS';
const AK_SELECTED_REPORT = 'AK_SELECTED_REPORT';
const AK_ACTION_FETCH_START = 'AK_ACTION_FETCH_START';
const AK_ACTION_FETCH_END = 'AK_ACTION_FETCH_END';
const AK_CLEAR_EDIT_REPORT = 'AK_CLEAR_EDIT_REPORT';

const GET_SELECTED_REPORT_FULL_INFO = 'GET_SELECTED_REPORT_FULL_INFO'

const InitialState = {
    ak_reports: [],
    ak_selected_report: null,
    ak_action_fetching: false,
    reportForEdit: null,
    content_url: null,
    entities: [],
    reportFiles: [],
    reportUsers: null,
    totalCount: 0,
    listLoading: false,
    actionsLoading: false,
    cdnLink: null,
    names: null,
}

const reportsReducer = (state = InitialState, action) => {
    switch (action.type) {
        case AK_SET_REPORTS: {
            return {
                ...state,
                ak_reports: [...action.reports],
            }
        }
        case AK_SELECTED_REPORT: {
            return {
                ...state,
                ak_selected_report: action.payload,
            }
        }
        case AK_ACTION_FETCH_START: {
            return {
                ...state,
                ak_action_fetching: true,
            }
        }
        case AK_ACTION_FETCH_END: {
            return {
                ...state,
                ak_action_fetching: false,
            }
        }

        case SET_REPORTS:
            return { ...state, entities: [...action.reports], totalCount: action.reports.length }
        case CLEAR_EDIT_REPORT:
            return {
                ...state,
                reportForEdit: null,
                reportUsers: null,
                content_url: null,
                reportFiles: []
            }

        case SET_EDIT_REPORT:
            return {
                ...state,
                reportForEdit: {
                    id: action.payload.id,
                    title: action.payload.title,
                    annotation: action.payload.annotation,
                    status: getStringFromBool(action.payload.status)
                },
                reportUsers: action.payload.users_reports_relation,
                content_url: action.payload.content_url,
                reportFiles: action.payload.content_keys
            }
        case SET_LOADING:
            return {
                ...state,
                listLoading: action.isLoading
            }
        case SET_ACTIONS_LOADING:
            return {
                ...state,
                actionsLoading: action.isLoading
            }
        case GET_SELECTED_REPORT_FULL_INFO:
            return {
                ...state,
                cdnLink: action.payload.storage_url,
                names: action.payload.content_keys
            }
        default:
            return state;
    }
}

const setEditReport = (payload) => ({ type: SET_EDIT_REPORT, payload });
const setReports = (reports) => ({ type: SET_REPORTS, reports });
const setLoading = (isLoading) => ({ type: SET_LOADING, isLoading });
const setActionsLoading = (isLoading) => ({ type: SET_ACTIONS_LOADING });
export const clearEditReport = () => ({ type: CLEAR_EDIT_REPORT });

// axoft
const ak_setReports = (reports) => ({ type: AK_SET_REPORTS, reports });
export const ak_clearEditReport = () => ({ type: AK_CLEAR_EDIT_REPORT });

export const ak_setSelectedReport = (payload) => ({
    type: AK_SELECTED_REPORT,
    payload,
})

export const ak_actionFetchStart = () => ({
    type: AK_ACTION_FETCH_START,
})

export const ak_actionFetchEnd = () => ({
    type: AK_ACTION_FETCH_END,
})

export const setSelectedReportFullInfo = payload => ({ type: GET_SELECTED_REPORT_FULL_INFO, payload })


export const requestReports = () => async (dispatch) => {
    dispatch(ak_actionFetchStart())
    await reportsAPI.getAllReports()
        .then(r => {
            if (r.status === 200) {
                dispatch(ak_setReports(r.data.data));
                dispatch(setLoading(false));
            }
        })
        .finally(() => {
            dispatch(ak_actionFetchEnd())
        })
}

export const createReport = (data) => async (dispatch) => {
    try {
        /*const postData = {
            ...data,
            report_status: Number(data.report_status),
        }*/
        dispatch(ak_actionFetchStart())

        const result = await reportsAPI.addReport(data);
        //const state = getState()

        if (result.data.code === 200) {
            /*debugger
            const newReports = state.reports.ak_reports

            newReports.push({
                ...postData,
                report_uuid: result.data.response.report_uuid,
            })
            dispatch(ak_setReports(newReports));*/
            dispatch(requestReports());
            return true;
        }
        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const updateReport = (report) => async (dispatch, getState) => {
    try {
        const postData = {
            ...report,
            report_status: Number(report.report_status),
        }
        dispatch(ak_actionFetchStart())

        const result = await reportsAPI.updateReport(postData);
        const state = getState()

        if (result.data.code === 200) {
            const newReports = state.reports.ak_reports.map(report => {
                if (report.report_uuid === postData.report_uuid) {
                    return postData;
                }

                return report
            });
            //dispatch(ak_setReports(newReports));
            dispatch(requestReports());
            return true;
        }
        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const deleteReport = (id) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())

        const result = await reportsAPI.deleteReport(id);
        const state = getState()

        if (result.data.code === 200) {
            const newReports = state.reports.ak_reports.filter(report => report.report_uuid !== id);
            //dispatch(ak_setReports(newReports));
            dispatch(requestReports());
            return true;
        }
        return false;
    } catch (e) {
        return false;
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const getReportById = (id) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart());

        const result = await reportsAPI.getReport(id);
        console.log('getReportById result', result.data.data);

        if (result.data.code === 200) {
            const state = getState()

            const newReports = state.reports.ak_reports.map(report => {
                if (report.report_uuid === id) {
                    return result.data.data;
                }

                return report;
            })

            //dispatch(ak_setReports(newReports))

            if (state.reports.ak_selected_report.report_uuid === id) {
                dispatch(ak_setSelectedReport(result.data.data))
            }
        }
    } catch {
    } finally {
        dispatch(ak_actionFetchEnd());
    }
}

export const updateReportUsers = (eventId, usersIds) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart());

        const result = await reportsAPI.updateReportUsers(eventId, usersIds);

        if (result.data.code === 200) {
            const state = getState()

            /*const newReports = state.reports.ak_reports.map(report => {
                if (report.report_uuid === eventId) {
                    // return result.data.data;
                }

                return report;
            })*/

            //dispatch(ak_setReports(newReports))
            //dispatch(requestReports());

            if (state.reports.ak_selected_report.report_uuid === eventId) {
                dispatch(ak_setSelectedReport(result.data.data))
                //dispatch(requestReports());
            }
        }
    } catch {
    } finally {
        dispatch(ak_actionFetchEnd());
    }
}
//

export const clearReportFields = () => async (dispatch) => {
    await Promise.all([
        dispatch(clearEditReport()),
    ]);
}

//get report content
export const getReportFullInfo = (data, event_uuid = '123') => async (dispatch) => {
    try {
        dispatch(ak_actionFetchStart())
        const postData = {
            event_uuid,
            ...data
        }

        const result = await reportsAPI.getFullReportInfo(postData);

        if (result.data.code === 200) {
            dispatch(setSelectedReportFullInfo(result.data.response));
            return true;
        }

        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}
//update report content
export const updateReportContent = (data, event_uuid = '123') => async (dispatch) => {
    try {
        dispatch(ak_actionFetchStart())
        const postData = {
            event_uuid,
            ...data
        }
        const result = await reportsAPI.updateReportContent(postData);

        if (result.data.code === 200) {
            dispatch(getReportFullInfo(postData.local_id));
            return true;
        }

        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

// export const createReport = (event) => async (dispatch) => {
//     dispatch(setLoading(true));
//     dispatch(clearEditReport());
//     await Promise.all([
//         reportsAPI.addReport(event).then(() => {
//             dispatch(requestReports());
//         })
//     ]);
// }

export default reportsReducer;
