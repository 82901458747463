import { ax, getToken } from "./api-setup";

//Constants

export const LOGIN_URL = "api/login";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const LOGOUT_URL = "api/logout";
export const ME_URL = "api/me";

export const LOGIN = 'api/account/enter/login'
export const REGISTER = 'api/account/enter/registration'
export const CURRENT_SESSION = 'api/account/enter/current-session'
export const LOGOUT = '/api/account/enter/logout'
export const ACCEPT_AGREEMENT = '/api/account/enter/accept-agreement'

//Methods

export const authAPI = {
  registration(user) {
    return ax.post(REGISTER, {
      email: user.login,
      password: user.password,
      first_name: user.first_name,
      last_name: user.last_name,
      company_name: user.company_name,
      company_position: user.company_position,
      secon_name: user.secon_name,
      shtani: Number(user.Shtani),
      sex: user.sex,
    });
  },
  login(user) {
    return ax.post(LOGIN, {
      email: user.email,
      password: user.password,
    })
  },
  getCurrentSession() {
    const uuid = localStorage.getItem('token')

    return ax.post(
      CURRENT_SESSION,
      {
        current_uuid: localStorage.getItem('token'),
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  acceptAgreement() {
    const uuid = localStorage.getItem('token')

    return ax.post(ACCEPT_AGREEMENT,
      null,
      {
        headers: {
          'x-viexpo-session-token': uuid,
        },
      },
    )
  },

  me() {
    return ax.get(`${ME_URL}/${getToken()}`)
  },
  // login(values){
  //     return ax.post(LOGIN_URL, {login: values.email,
  //         password: values.password,
  //         rememberMe: values.rememberMe});
  // },
  logout() {
    const uuid = localStorage.getItem('token')

    return ax.post(
      LOGOUT,
      null,
      {
        headers: {
          'x-viexpo-session-token': uuid,
        },
      },
    )
  }
}