/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { EventsList } from "./EventsList";

const eventContent = {
    axoft: {
        eventsDescription: [{
            header: 'IT Expo 2021. Technology. Expertise. Community',
            title: 'Мультиформатное виртуальное пространство для развития бизнеса и решения IT-задач',
            eventDate: '30.11.2021',
            events: ['Аксофт', 'Сколково'],
            eventsID: ['9693986f-a0ad-4f50-9b98-4f740faa942c', '9693986f-a0ad-4f50-9b98-4f740faasf3w'],
            eventRoutes: ["online-1", "online-2"],
        }],
        eventImg: 'axoftBG.jpg'
    },
    viexpo: {
        eventsDescription: [
            {
                header: 'Пилотный проект по визуализации классов типовой школы Москвы',
                title: 'ViExpo 2022. IT. Expositions. Business',
                eventDate: '14.03.2022',
                eventsID: ['9693986f-a0ad-4f50-9b98-4f740faao49d'],
                events: ['Посетить'],
                eventRoutes: ["technopolis-school"],
            },
            {
                header: 'Пилотный проект. Выставочный зал резидентов технополис Москва',
                title: 'ViExpo 2022. IT. Expositions. Business',
                eventDate: '14.03.2022',
                events: ['Посетить'],
                eventsID: ['9693986f-a0ad-4f50-9b98-4f740faadk38'],
                eventRoutes: ["technopolis-expo"],
            },
        ],
        eventImg: 'technopolisBG.jpg'
    },
    mtpp: {
        eventsDescription: [{
            header: 'Московская торгово-промышленная палата. Пилотный проект (демо).',
            title: 'Voltep.online 2022.',
            eventDate: '30.11.2021',
            events: ['Посетить'],
            eventsID: ['9693986f-a0ad-4f50-9b98-4f740fef3264'],
            eventRoutes: ["mtpp"],
        }],
        eventImg: 'mtpp.jpeg'
    },
    h2b: {
        eventsDescription: [{
            header: 'Пилотный проект виртуального пространства',
            title: 'Voltep.online 2022.',
            eventDate: '15.04.2022',
            events: ['Посетить'],
            eventsID: ['9693986f-a0ad-4f50-9b98-4f740fsdf3vf'],
            eventRoutes: ["h2b"],
        }],
        eventImg: 'h2b.jpg'
    },

}

export function EventsWidget() {
    return (
        <>
            <EventsList company={eventContent.mtpp} />
            <EventsList company={eventContent.h2b} />
            <EventsList company={eventContent.viexpo} />
            <EventsList company={eventContent.axoft} />
        </>
    );
}
